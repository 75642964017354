import { useState, useEffect } from "react";
import PlayerModal from "../modal/player-modal";
import { Link } from 'react-router-dom';

import './box-content.css';

const BoxContent = () => {
    const [modal, setModal] = useState(false);
    const Toggle = () => setModal(!modal);
    const [nextEventDate, setNextEventDate] = useState([]);
    //const [videoDate, newVideoDate] = useState(null);

    function getDayName(dayNumber) {
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return dayNames[dayNumber];
    }

    /*##################### update this audio file path here until it's hooked to backend */
    const videoFilePath = () => {
        return "this not working"; //"/audios/December-25-2022.m4a";//
    }

    const videoDate = () => {
        return "02/09/2025";
    }
    /*#####################*/

    useEffect(() => {
        //Calculate desired dates
        const now = new Date();
        const currentDayOfWeek = now.getDay();
        const currentHour = now.getHours();
        let textResult;
        let dateResult;
        let headerText;
        let daysUntilNextDay;
        if ((currentDayOfWeek === 0 && currentHour >= 12) || 
            (currentDayOfWeek === 1 || currentDayOfWeek === 2) || 
            (currentDayOfWeek === 3 && currentHour <= 19)) {
            //if after 12 PM in Sunday and before 7 PM on Wednesday
            let serviceTime = "7 P.M.";
            daysUntilNextDay = (3 - currentDayOfWeek + 7) % 7;
            let nextDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysUntilNextDay);
            headerText = "Upcoming Event";
            textResult = getDayName(nextDate.getDay()) + " Bible Class";
            dateResult  = nextDate.getMonth() + 1 + "/" + nextDate.getDate() + "/" + nextDate.getFullYear()+ ", "+ serviceTime;
        }
        else if ((currentDayOfWeek === 0 && currentHour >= 10) && (currentDayOfWeek === 0 && currentHour <= 12)) {
            //if between 10 AM and 12 PM on Sunday
            let nextDate =  new Date(now.getFullYear(), now.getMonth(), now.getDate());
            textResult = getDayName(nextDate.getDay()) + " Bible Class & Worship";
            headerText = "Current Event";
            dateResult  = nextDate.getMonth() + 1 + "/" + nextDate.getDate() + "/" + nextDate.getFullYear();
        }
        else if ((currentDayOfWeek === 3 && currentHour >= 19) && (currentDayOfWeek === 3 && currentHour <= 20)) {
            //if between 7 PM and 8 PM on Wednesday
            let nextDate =  new Date(now.getFullYear(), now.getMonth(), now.getDate());
            textResult = getDayName(nextDate.getDay()) + " Bible Class";
            headerText = "Current Event";
            dateResult  = nextDate.getMonth() + 1 + "/" + nextDate.getDate() + "/" + nextDate.getFullYear();
        }
        else { 
            //if after 8 PM on Wednesday and before 10 AM on Sunday
            let serviceTime = "10 A.M.";
            daysUntilNextDay = (7 - currentDayOfWeek + 7) % 7;
            let nextDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysUntilNextDay);
            headerText = "Upcoming Event";
            textResult = getDayName(nextDate.getDay()) + " Bible Class & Worship";
            dateResult  = nextDate.getMonth() + 1 + "/" + nextDate.getDate() + "/" + nextDate.getFullYear()+ ", "+ serviceTime;
        }  
        setNextEventDate([headerText, textResult, dateResult]);
    }, []);

    //This was done to extract date  from audio/video filename when the filename were /audios/December-25-2022.m4a
    //   useEffect(() => {
    //     // Extract date portion from the string
    //     const datePattern = /([A-Za-z]+)-(\d{1,2})-(\d{4})/;
    //     const videoFile = videoFilePath();
    //     const match = videoFile.match(datePattern);

    //     if (match) {
    //     const [, monthStr, dayStr, yearStr] = match;
    //     const monthIndex = new Date(Date.parse(monthStr + " 1, 2022")).getMonth(); // Month index is zero-based

    //     const properDate = new Date(Number(yearStr), monthIndex, Number(dayStr));
    //     newVideoDate(properDate.toDateString());
    //     } else {
    //     console.log("Date not found in the file path.");
    //     }
    // }, []);
    

    return ( 
        <div className="box text-center">
            <div className="box-item">
                <h6 className="box-title">{nextEventDate[0]}</h6>
                <h3>{nextEventDate[1]}</h3>
                <p>{nextEventDate[2]}</p>
            </div>
            
            <div className="box-item">
                <h6 className="box-title">
                    <span>Watch Sermon Recorded On</span><br />
                    {videoDate()}
                </h6>
                
                <p>
                    <button type="button" className="btn btn-outline-primary play-control clickme" data-toggle="modal" data-target="#exampleModal" onClick={() => Toggle()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
                    <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/>
                    </svg>
                    </button>                  
                </p>
                <h6 className="section-title mt-4"><Link to="/recent-sermons">Watch sermons from archive</Link></h6>
            </div>
            <PlayerModal title="Sermon Player" show={modal} close={Toggle} videoFilePath={videoFilePath} recordedDate={videoDate} />
        </div>
     );
}
 
export default BoxContent;