import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactPlayer from 'react-player';

import './modal.css';

const PlayerModal = ({ show, close, videoFilePath, recordedDate }) => {
  return (
      <>
          {
              
     show ?
     
        <Modal show={() => show()} onHide={() => close()} onClick={(e) => e.stopPropagation()} centered>
        <Modal.Header>
              <Modal.Title>{recordedDate()}</Modal.Title>
		   <button className="close" onClick={() => close()}>
              x
           </button>
        </Modal.Header>  
            <Modal.Body>
              <iframe width="475" height="315" src="https://youtu.be/s0b4UuZK6FY" title="YouTube video player" controls="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                     {/* <ReactPlayer
                      url={videoFilePath()}
                      controls={ true}
                      />     */}
              {/* <p>We are working on making the recent sermon available. Please visit back!</p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      : null
     }
    </>
  );
};

export default PlayerModal;